exports.modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        width: 400,
        transform: "translate(-50%, -50%)",
    },
};
