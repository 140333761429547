export const CLIENT_LIST_REQUEST = "CLIENT_LIST_REQUEST";
export const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
export const CLIENT_LIST_FAIL = "CLIENT_LIST_FAIL";
export const CLIENT_LIST_RESET = "CLIENT_LIST_RESET";

export const CLIENT_CREATE_REQUEST = "CLIENT_CREATE_REQUEST";
export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
export const CLIENT_CREATE_FAIL = "CLIENT_CREATE_FAIL";

export const CLIENT_DETAILS_REQUEST = "CLIENT_DETAILS_REQUEST";
export const CLIENT_DETAILS_SUCCESS = "CLIENT_DETAILS_SUCCESS";
export const CLIENT_DETAILS_FAIL = "CLIENT_DETAILS_FAIL";
export const CLIENT_DETAILS_RESET = "CLIENT_DETAILS_RESET";

export const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_UPDATE_FAIL = "CLIENT_UPDATE_FAIL";
export const CLIENT_UPDATE_RESET = "CLIENT_UPDATE_RESET";

export const CLIENT_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_FAIL = "CLIENT_DELETE_FAIL";
export const CLIENT_DELETE_RESET = "CLIENT_DELETE_RESET";
