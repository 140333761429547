export const ROLE_LIST_REQUEST = "ROLE_LIST_REQUEST";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const ROLE_LIST_FAIL = "ROLE_LIST_FAIL";
export const ROLE_LIST_RESET = "ROLE_LIST_RESET";

export const ROLE_CREATE_REQUEST = "ROLE_CREATE_REQUEST";
export const ROLE_CREATE_SUCCESS = "ROLE_CREATE_SUCCESS";
export const ROLE_CREATE_FAIL = "ROLE_CREATE_FAIL";

export const ROLE_DELETE_REQUEST = "ROLE_DELETE_REQUEST";
export const ROLE_DELETE_SUCCESS = "ROLE_DELETE_SUCCESS";
export const ROLE_DELETE_FAIL = "ROLE_DELETE_FAIL";
export const ROLE_DELETE_RESET = "ROLE_DELETE_RESET";