export const TABLE_ALL_REQUEST = "TABLE_ALL_REQUEST";
export const TABLE_ALL_SUCCESS = "TABLE_ALL_SUCCESS";
export const TABLE_ALL_FAIL = "TABLE_ALL_FAIL";
export const TABLE_ALL_RESET = "TABLE_ALL_RESET";

export const TABLE_LIST_REQUEST = "TABLE_LIST_REQUEST";
export const TABLE_LIST_SUCCESS = "TABLE_LIST_SUCCESS";
export const TABLE_LIST_FAIL = "TABLE_LIST_FAIL";
export const TABLE_LIST_RESET = "TABLE_LIST_RESET";

export const TABLE_CREATE_REQUEST = "TABLE_CREATE_REQUEST";
export const TABLE_CREATE_SUCCESS = "TABLE_CREATE_SUCCESS";
export const TABLE_CREATE_FAIL = "TABLE_CREATE_FAIL";

export const TABLE_DETAILS_REQUEST = "TABLE_DETAILS_REQUEST";
export const TABLE_DETAILS_SUCCESS = "TABLE_DETAILS_SUCCESS";
export const TABLE_DETAILS_FAIL = "TABLE_DETAILS_FAIL";
export const TABLE_DETAILS_RESET = "TABLE_DETAILS_RESET";

export const TABLE_UPDATE_REQUEST = "TABLE_UPDATE_REQUEST";
export const TABLE_UPDATE_SUCCESS = "TABLE_UPDATE_SUCCESS";
export const TABLE_UPDATE_FAIL = "TABLE_UPDATE_FAIL";
export const TABLE_UPDATE_RESET = "TABLE_UPDATE_RESET";

export const TABLE_DELETE_REQUEST = "TABLE_DELETE_REQUEST";
export const TABLE_DELETE_SUCCESS = "TABLE_DELETE_SUCCESS";
export const TABLE_DELETE_FAIL = "TABLE_DELETE_FAIL";
export const TABLE_DELETE_RESET = "TABLE_DELETE_RESET";
